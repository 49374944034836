import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { CssBaseline, Container } from "@mui/material";
import Sidebar from "../common/Sidebar";
import RingsProgress from "../../../utils/RingsProgress";
import AppBar from "../common/Appbar";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
const MainLayout = ({ children }) => {
  const { applicationId } = useParams();

  const location = useLocation();

  const isApplicationIncludesInUrl =
    location?.pathname.includes("/application");

  const isSubmittedIncludesInUrl = location?.pathname.includes("/submitted");
  const { isOpenResources } = useSelector((state) => state.insurance);
  const [openResources, setOpenResources] = useState(isOpenResources);

  useEffect(() => {
    setOpenResources(isOpenResources);
  }, [isOpenResources]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Box
        component="main"
        sx={{
          backgroundColor: isApplicationIncludesInUrl ? "#fff" : "#f2f2f9",
          flexGrow: 1,
          height: openResources ? "100vh" : "auto",
          overflow: "auto",
        }}
      >
        <AppBar />
        {applicationId && !isSubmittedIncludesInUrl && <RingsProgress />}
        <Box
          paddingTop={0}
          paddingLeft={{ xs: 3, lg: 6 }}
          paddingRight={{ xs: 3, lg: 6 }}
          paddingBottom={7}
        >
          <Container
            maxWidth={"1500px"}
            sx={{ mt: 12, mb: 4, maxWidth: "1500px" }}
          >
            {children}
          </Container>
        </Box>
        {/* <Box
          sx={{
            background: "#F2F1F7",
            py: "6px",
            position: "fixed",
            bottom: 0,
            width: "100%",
            boxShadow: "31px 31px 40px #333",
          }}
        >
          <Typography
            sx={{
              transform: "translateX(30%)",
            }}
          >
            @{moment().format("YYYY")} powered by{" "}
            <a
              target="_blank"
              style={{ marginLeft: "4px" }}
              href="https://invoff.com/"
              rel="noreferrer"
            >
              Invoff.com
            </a>
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};
export default MainLayout;
