export const getApplicationPercentage = ({ percentage, label }) => {
  if (percentage >= 5) {
    return label === "default"
      ? `${percentage.toFixed()}% complete`
      : label
      ? `${percentage.toFixed()}${label}`
      : percentage.toFixed();
  } else {
    return null;
  }
};
